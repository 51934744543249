<template>
  <div>
    <title>SIM INVENTORY ~ ADD SUPPLIER DATA</title>
    <section class="content-header">
      <h1>
        Add Supplier Data
        <br />
        <h4>Please enter supplier master data</h4>
      </h1>
      <ol class="breadcrumb">
        <li>
          <a href="#"><i class="fa fa-dashboard"></i> Home</a>
        </li>
        <li class="active">Master Supplier</li>
      </ol>
    </section>
    <section class="content">
      <div v-if="loading" class="load">
        <img width="130" src="@/assets/loader.gif" alt="" />
      </div>
      
      <div class="row">
        <!-- left column -->
        <div class="col-md-12">
          <!-- general form elements -->
          <div class="box box-primary">
            <div class="box-header with-border">
              <h3 class="box-title">Supplier Data</h3>
            </div>
            <!-- /.box-header -->
            <!-- form start -->
            <form role="form">
              <div class="box-body">
                <div class="form-group">
                  <label for="exampleInputEmail1">Supplier Code</label>
                  <input
                    type="text"
                    v-model="isidata.kodesupplier"
                    autocomplete="off"
                    class="form-control"
                    placeholder="Supplier Code"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Supplier Name</label>
                  <input
                    type="text"
                    v-model="isidata.namasupplier"
                    autocomplete="off"
                    class="form-control"
                    placeholder="Supplier Name"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Alamat Supplier</label>
                  <input
                    type="text"
                    v-model="isidata.alamatsupplier"
                    autocomplete="off"
                    class="form-control"
                    placeholder="Alamat Supplier"
                  />
                </div>
              </div>

              <div class="box-footer">
                <button
                  @click="saveData()"
                  type="button"
                  class="btn btn-primary"
                > <i class="fa fa-save"></i>
                  Simpan
                </button>
                &nbsp;
                <router-link to="/supplier">
                  <button class="btn btn-success"><i class="fa fa-angle-double-left"></i> Back</button>
                </router-link>
              </div>
            </form>
          </div>
        </div>
      
      </div>
    </section>
  </div>
</template>
<script>
import swal from "sweetalert";
var axios = require("axios");
export default {
  name: "addmasterdata",
  data() {
    return {
      loading: false,
      isidata: {
        kodesupplier: "",
        namasupplier: "",
        alamatsupplier: ""
      }
    };
  },
  methods: {
    async saveData() {
      this.loading = true;
      var kode = this.isidata.kodesupplier;
      var nama = this.isidata.namasupplier;
      var alamat = this.isidata.alamatsupplier;
      if (kode == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Supplier Code can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else if (nama == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Supplier Name can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else if (alamat == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Address can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else {
        var kodeuser = sessionStorage.getItem("kodeuser");
        const paramdata = {
          kode_supplier: this.isidata.kodesupplier,
          nama_supplier: this.isidata.namasupplier,
          alamat: this.isidata.alamatsupplier,
          kode_user: kodeuser
        };
        // const tokenlogin = localStorage.getItem("token");
        const tokenlogin = sessionStorage.getItem("token");
        // const urlAPISaveData = "http://26.183.23.191/inventory/backend/inventory/api/supplier/savesupplier";
        const urlAPISaveData = this.$apiurl + "supplier/savesupplier";
        const headers = {
          Accept: "application/json",
          Authorization: tokenlogin
        };
        axios
          .post(urlAPISaveData, paramdata, { headers })
          .then((resp) => {
            if (resp.data.status == true) {
              swal({
                icon: "success",
                title: "Success",
                text: "Save data successfully",
                showConfirmButton: false
              });
              this.isidata.kodesupplier = "";
              this.isidata.namasupplier = "";
              this.isidata.alamatsupplier = "";
              this.loading = false;
              this.$router.push({ name: "supplier" });
            } else {
              swal({
                icon: "warning",
                title: "Warning",
                text: "Save data failed",
                showConfirmButton: false
              });
              this.loading = false;
            }
          })
          .catch((err) => {
            swal({
              icon: "warning",
              title: "Warning",
              text: err.response.data,
              showConfirmButton: false
            });
            console.log(err);
            this.loading = false;
          });
      }
    }
  }
};
</script>
<style>
.load {
  width: 20%;
  margin: 0 auto;
}
</style>
